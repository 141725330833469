import { graphql, PageProps } from "gatsby"
import tw from "twin.macro"
import Seo from "../components/SEO"
import AnalyticsEventsClient from "../clients/AnalyticsEvents"
import { useEffect } from "react"

const TermsAndConditionsPage: React.FC<{data: {termsDocument: {html: string}}}> = ({ data }) => {
  useEffect(() => {
    AnalyticsEventsClient.event({
      eventType: "click",
      eventName: "click_terms", 
      eventMessage: "User views terms and conditions",
      visitorID: localStorage.getItem("visitorID")
    })
  }, [])
    
  return (
    <div>
      <Seo title="Terms and Conditions" />
      <main css={[tw`py-12 bg-gray-200`]}>
        <div css={[tw`container mx-auto px-8`]}>
          <h1 css={[tw`mb-8 text-gray-600`]}>Terms and Conditions</h1>
          <div
            css={[tw`text-gray-600 leading-7`]}
            dangerouslySetInnerHTML={{
              __html: data.termsDocument.html.replaceAll(/\\n/g, "<br />"),
            }}
          />
        </div>
      </main>
    </div>
  )
}

export const data = graphql`
  {
    termsDocument {
      html
    }
  }
`

export default TermsAndConditionsPage
